import Axios from 'app/apis';

const loginRequest = (data: any) => {
  return Axios.post('/auth/login', data);
};

const logoutRequest = () => {
  return Axios.post('/auth/logout');
};

const userRequest = () => {
  return Axios.get('/users/me');
};

export { loginRequest, logoutRequest, userRequest };

import * as React from 'react';
import {
  unstable_HistoryRouter as HistoryRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import history from 'utils/history';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
// component
import { GlobalStyle } from 'styles/global-styles';
import { PATH } from './constants/path';
import 'react-toastify/dist/ReactToastify.css';

//page
import useToken from 'app/hooks/useToken';
import MainLayout from './components/Layouts';
import { MetaToast } from 'app/components/Modals/MetaToast';
import { selectToast } from './pages/ToastPage/slice/selectors';
import { SignInUpPage } from 'app/pages/AuthPage';
import { Point } from 'app/pages/Point/Loadable';
import { Activity } from 'app/pages/Activity/Loadable';
import { Gift } from 'app/pages/Gift/Loadable';
import { Analytic } from 'app/pages/Analytic';
import { SettingTime } from 'app/pages/SettingTime/Loadable';
import { PaymentHistory } from './pages/PaymentHistory/Loadable';

const Layout = () => {
  const { token } = useToken();
  if (!token) {
    return <Navigate to={PATH.LOGIN} replace />;
  }
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

export function App() {
  const {
    isOpen,
    message,
    type,
    titleButton,
    urlRedirect,
    handleFunc,
    isErrorToken,
    imageElement,
    buttonElement,
    titleCustom,
    children,
  } = useSelector(selectToast);

  const [theme] = React.useState({
    color_hover: '#A21749',
    color_primary: '#FF347D',
    color_secondary: '#F4887B',
    color_warning: '#E10000',
    color_success: '#28a745',
    color_confirm: '#1677ff',
  });

  return (
    <ThemeProvider theme={theme}>
      <HistoryRouter history={history}>
        <Routes>
          <Route path={PATH.LOGIN} element={<SignInUpPage />} />

          <Route path={'/'} element={<Layout />}>
            <Route path={PATH.HOME} element={<Point />} />
            <Route path={PATH.PAYMENT_HISTORY} element={<PaymentHistory />} />
            <Route path={PATH.ACTIVITY} element={<Activity />} />
            <Route path={PATH.GIFT} element={<Gift />} />
            <Route path={PATH.ANALYTIC} element={<Analytic />} />
            <Route path={PATH.SETTING_TIME} element={<SettingTime />} />
          </Route>
        </Routes>
        <GlobalStyle />
        <ToastContainer />
        <MetaToast
          isOpen={isOpen}
          typeToast={type ?? 'success'}
          message={message}
          titleButton={titleButton}
          urlRedirect={urlRedirect}
          handleFunc={handleFunc}
          isErrorToken={isErrorToken}
          imageElement={imageElement}
          buttonElement={buttonElement}
          titleCustom={titleCustom}
          children={children}
        />
      </HistoryRouter>
    </ThemeProvider>
  );
}

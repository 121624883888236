import { useDispatch } from 'react-redux';
import { Image, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Cookies from 'js-cookie';

import { MetaButton } from 'app/components/Forms';
import { SuccessIcon, ErrorIcon, ConfirmIcon, SorryIcon } from 'styles/image';
import { useToastSlice } from 'app/pages/ToastPage/slice';
import { PATH } from 'app/constants/path';

interface IPropModal {
  isOpen: boolean;
  typeToast:
    | 'success'
    | 'verified'
    | 'error'
    | 'confirm'
    | 'sorry'
    | 'custom'
    | 'customChild';
  message?: string;
  titleButton?: string;
  urlRedirect?: any;
  handleFunc?: any;
  isErrorToken?: any;
  imageElement?: any;
  buttonElement?: any;
  titleCustom?: any;
  children?: any;
}

export const MetaToast = ({
  isOpen,
  typeToast = 'success',
  message,
  titleButton = 'OK',
  urlRedirect,
  handleFunc,
  isErrorToken,
  imageElement,
  buttonElement,
  titleCustom,
  children,
}: IPropModal) => {
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const isAuth = location.pathname.includes('auth/');

  const { actions } = useToastSlice();

  useEffect(() => {
    setIsOpenModal(isOpen);
  }, [isOpen]);

  const handleOk = useCallback(() => {
    dispatch(actions.closeToast({}));

    if (isErrorToken) {
      Cookies.remove('access_token_admin');

      window.location.href = PATH.LOGIN;
    }
  }, [dispatch, isErrorToken]);

  if (!message || message === '') {
    return <></>;
  }

  return (
    <ModalCustom
      typeToast={typeToast}
      centered
      open={true}
      onCancel={handleOk}
      closable={false}
      isAuth={isAuth}
      className={typeToast === 'customChild' ? 'modal-custom-child' : ''}
      title={
        typeToast === 'success' ? (
          <HeaderCustom typeToast={typeToast}>
            <Image
              width={48}
              height={40}
              src={SuccessIcon}
              alt="success-icon"
              preview={false}
            />
          </HeaderCustom>
        ) : typeToast === 'verified' ? (
          <HeaderCustom typeToast={typeToast}>
            <Image
              width={48}
              height={40}
              src={SuccessIcon}
              alt="success-icon"
              preview={false}
            />
          </HeaderCustom>
        ) : typeToast === 'error' ? (
          <HeaderCustom typeToast={typeToast}>
            <Image
              width={36}
              height={36}
              src={ErrorIcon}
              alt="error-icon"
              preview={false}
            />
          </HeaderCustom>
        ) : typeToast === 'sorry' ? (
          <HeaderCustom typeToast={typeToast}>
            <Image
              width={48}
              height={48}
              src={SorryIcon}
              alt="sorry-icon"
              preview={false}
            />
          </HeaderCustom>
        ) : typeToast === 'custom' ? (
          <HeaderCustom typeToast={typeToast}>
            {' '}
            <Image
              width={48}
              height={48}
              src={imageElement}
              alt="sorry-icon"
              preview={false}
            />
          </HeaderCustom>
        ) : typeToast === 'customChild' ? (
          <></>
        ) : typeToast === 'confirm' ? (
          <HeaderCustom typeToast={typeToast}>
            {' '}
            <Image
              width={48}
              height={48}
              src={imageElement}
              alt="sorry-icon"
              preview={false}
            />
          </HeaderCustom>
        ) : (
          <HeaderCustom typeToast={typeToast}>
            <Image
              width={36}
              height={36}
              src={ConfirmIcon}
              alt="error-icon"
              preview={false}
            />
          </HeaderCustom>
        )
      }
      footer={
        <FooterCustom>
          {typeToast === 'confirm' ? (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '120px 120px',
                gap: '20px',
              }}
            >
              <CustomMetaButton
                typeToast={typeToast}
                type="primary"
                key="back"
                onClick={handleFunc}
                text="Yes"
                style={{ width: '122px' }}
              />
              <CustomMetaButton
                className="btn-cancel"
                typeToast={typeToast}
                type="default"
                key="back"
                onClick={handleOk}
                text="No"
                style={{
                  width: '122px',
                }}
              />
            </div>
          ) : typeToast === ('success' || 'verified') ? (
            <CustomMetaButton
              typeToast={typeToast}
              type="primary"
              key="back"
              onClick={() => {
                if (urlRedirect) {
                  dispatch(actions.closeToast({}));
                  navigate(urlRedirect);
                } else {
                  dispatch(actions.closeToast({}));
                }
              }}
              text={titleButton}
              style={{ width: '122px' }}
            />
          ) : typeToast === 'custom' ? (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '120px 120px',
                gap: '20px',
              }}
            >
              <div
                style={{ width: '100%' }}
                dangerouslySetInnerHTML={{ __html: buttonElement }}
              />

              <CustomMetaButton
                className="btn-cancel"
                typeToast={typeToast}
                type="default"
                key="back"
                onClick={handleOk}
                text="いいえ"
                style={{
                  width: '122px',
                }}
              />
            </div>
          ) : typeToast === 'customChild' ? (
            <></>
          ) : (
            <CustomMetaButton
              typeToast={typeToast}
              type="primary"
              key="back"
              onClick={handleOk}
              text={titleButton}
              style={{ width: '122px' }}
            />
          )}
        </FooterCustom>
      }
    >
      <BoxBody isArr={Array.isArray(message)}>
        <h1
          style={{
            textAlign: 'center',
            margin: 'auto',
          }}
        >
          {typeToast === 'success'
            ? 'Successfully!'
            : typeToast === 'verified'
            ? 'Verified!'
            : typeToast === 'error'
            ? 'Error!'
            : typeToast === 'custom'
            ? titleCustom
            : typeToast === 'customChild'
            ? ''
            : typeToast === 'confirm' && titleCustom
            ? titleCustom
            : 'Sorry!'}
        </h1>
        {Array.isArray(message) ? (
          <>
            {message?.map((m, i) => (
              <p key={i}>{m}</p>
            ))}
          </>
        ) : (
          <p dangerouslySetInnerHTML={{ __html: message }} />
        )}

        {children && children}
      </BoxBody>
    </ModalCustom>
  );
};

const ModalCustom = styled(Modal)<any>`
  &.ant-modal {
    width: 580px !important;
  }

  .ant-modal-content {
    padding: 60px 100px;
    width: 100%;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto !important;
    border-radius: 20px;
    background-color: #231f1f;
    @media only screen and (max-width: 703px) {
      padding: 60px 31px;
    }

    .ant-image {
      background-color: #231f1f;
    }
  }
  &.ant-modal.modal-custom-child {
    .ant-modal-content {
      padding: 0px 40px;
      justify-content: flex-start;
      height: max-content;
      min-height: auto;
      .ant-modal-header,
      .ant-modal.footer {
        display: none;
      }
    }
  }
  .ant-modal-header {
    margin: 0;
  }
  .ant-modal-footer {
    width: 100%;
    margin-top: 0px;
  }
  .ant-modal-body {
    text-align: center;
    width: 100%;

    h1 {
      color: #fff;
    }
  }

  .custom-btn {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    height: 52px !important;
    width: 100% !important;
    font-size: 18px;
    background-color: #eb7d17;
    color: #fff;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const BoxBody = styled.div<any>`
  padding: 40px 0px;
  gap: 10px;
  display: flex;
  align-items: ${({ isArr }: any) => (isArr ? 'flex-start' : 'center')};
  justify-content: center;
  flex-direction: column;
  h1 {
    font-size: 34px;
    font-weight: 700;
    text-align: center;
    width: 100%;
  }
  h2 {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-black);
    width: 100%;
  }

  p {
    color: #928c8c;
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
`;

const FooterCustom = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderCustom = styled.div<{
  typeToast:
    | 'success'
    | 'verified'
    | 'error'
    | 'confirm'
    | 'sorry'
    | 'custom'
    | 'customChild';
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  span,
  svg {
    width: 48px;
    height: 48px;
  }
  path {
    fill: ${props =>
      props.typeToast === 'success'
        ? `${props.theme.color_success} !important`
        : props.typeToast === 'confirm'
        ? `${props.theme.color_confirm} !important`
        : `${props.theme.color_warning} !important`};
  }
`;

const CustomMetaButton = styled(MetaButton)<any>`
  height: 52px !important;
  width: 100% !important;
  font-size: 18px;
  &.ant-btn-primary {
    background-color: #ff347d;
    &:hover {
      background-color: #a21749;
    }
  }

  &.btn-cancel {
    border-color: transparent;
    color: #928c8c;
    background-color: #f3f4f6;
    &:hover {
      border-color: transparent !important;
      color: #9ca3af !important;
      opacity: 0.8;
      background-color: #f3f4f6 !important;
    }
  }
`;

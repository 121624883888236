import { ACCEPT_IMAGE, MAX_SIZE_IMAGE } from 'app/constants';

export const regexUsername = new RegExp(/^.{5,20}$/g);
export const regexPhone = new RegExp(
  /^(?:\d{10,11}|\d{3}-\d{3}-\d{4}|\d{4}-\d{1}-\d{4}|\d{4}-\d{2}-\d{4}|\d{4}-\d{3}-\d{4}|\d{4}-\d{4}-\d{4}|\d{3}-\d{4}-\d{4})$/g,
);
export const regexFormatPrice = new RegExp(/\B(?=(\d{3})+(?!\d))/g);
export const regexPassword = new RegExp(
  /^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=^.{8,32}$)/,
);

export const regexURL = new RegExp(
  /^(https:\/\/)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
);
export const regexEmail = new RegExp(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
);

export const getFirstLetter = name =>
  name
    ?.match(/\b(\w)/g)
    ?.join('')
    ?.toUpperCase();

export const regexStringAndNummber = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d).+$/);

export const regexValidNumber = new RegExp(/[0-9\b]|Arrow(Left|Right)|Delete/);

export const regexSeriNumber = new RegExp(/^[0-9a-zA-Z]*$/);

export const onKeyPress = e => {
  const { key } = e;
  if (isNaN(key) && key !== '-') {
    e.preventDefault();
  }
};

export const formatPhoneNumber = str => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);

  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3];
  }

  return null;
};

export const renderOption = options => {
  return [...options.map(item => ({ label: item.name, value: item.id }))];
};

export const renderOptionDevice = options => {
  return [
    ...options.map(item => ({ label: item.label, value: item.deviceId })),
  ];
};

export const renderOptionString = options => {
  return [...options.map(item => ({ label: item, value: item }))];
};

export const renderOptionId = options => {
  return [...options.map(item => ({ label: item.name, value: item.id }))];
};

export const renderOptionByName = options => {
  return [...options.map(item => ({ label: item.name, value: item.name }))];
};

export const renderOptionByValue = options => {
  return [...options.map(item => ({ label: item.label, value: item.value }))];
};

export const validateFileType = ({ type, size }, fileArr) => {
  if (type && !ACCEPT_IMAGE?.includes(type)) {
    return {
      open: true,
      message:
        'アップロードできる拡張子は<br>.png、.jpg、.jpeg、.tiff、.tifです。<br>ファイル拡張子をご確認ください。',
    };
  }

  if (size && size > MAX_SIZE_IMAGE) {
    return {
      open: true,
      message: 'ファイルサイズが大きすぎます。<br>10MB以下にしてください。',
    };
  }

  if (fileArr && fileArr?.length > 3) {
    return {
      open: true,
      message: 'アップロード枚数3枚を超えています。ご確認ください。',
    };
  }

  if (
    type &&
    ACCEPT_IMAGE?.includes(type) &&
    size &&
    size <= MAX_SIZE_IMAGE &&
    fileArr?.length <= 3
  )
    return { open: false, message: '' };
};

export const getBase64 = file => {
  return new Promise(resolve => {
    let baseURL: any = '';
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

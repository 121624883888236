import { CloseOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { Rule } from 'antd/es/form';
import React, { memo } from 'react';
import { AspectRatio } from 'react-aspect-ratio';
import { UploadFormItem } from './styles';

interface Props {
  name?: string;
  title?: string | React.ReactNode;
  label?: string | React.ReactNode;
  rules?: Rule[];
  disabled?: boolean;
  className?: string;
  required?: boolean;
  uploadProps?: any;
  fileUploaded?: {
    file: any;
    previewImage: any;
  } | null;
  image_url?: string;
  onRemoveFile?: () => void;
  type?: 'image' | 'audio';
  audioUrl?: string | null;
  alt?: string;
}

export const MetaUpload = memo((props: Props) => {
  const {
    name,
    title,
    label,
    rules,
    className,
    required,
    uploadProps,
    fileUploaded,
    image_url,
    onRemoveFile,
    type = 'image',
    audioUrl,
    alt = '',
  } = props;

  return (
    <UploadFormItem
      label={label}
      name={name}
      className={className}
      required={required}
      rules={rules}
    >
      <div className="upload-content">
        <div className="upload-content-box">
          <Upload {...uploadProps} className="upload-field-content">
            {title ? (
              title
            ) : (
              <p style={{ color: '#fff' }}>
                <a>Select a file</a> or drag in form
              </p>
            )}

            {type === 'image' &&
              image_url &&
              fileUploaded &&
              image_url.startsWith('data') && (
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    color: '#9CA3AF',
                    zIndex: 1,
                  }}
                  onClick={e => e.stopPropagation()}
                >
                  <span style={{ width: '160px' }} className="ellipsis-1">
                    {fileUploaded.file.name}
                  </span>{' '}
                  <CloseOutlined onClick={onRemoveFile} />
                </span>
              )}

            {type === 'audio' && fileUploaded && (
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  color: '#9CA3AF',
                  zIndex: 1,
                }}
                onClick={e => e.stopPropagation()}
              >
                <span style={{ width: '160px' }} className="ellipsis-1">
                  {fileUploaded.file.name}
                </span>{' '}
                <CloseOutlined onClick={onRemoveFile} />
              </span>
            )}
          </Upload>
        </div>

        <div className="upload-display">
          {type === 'image' && image_url && (
            <AspectRatio
              style={{
                width: '72px',
              }}
            >
              {!image_url.startsWith('data:image/tiff') ? (
                <img src={image_url} alt={alt} />
              ) : (
                <embed
                  width={200}
                  height={200}
                  src={image_url}
                  type="image/tiff"
                />
              )}
            </AspectRatio>
          )}

          {type === 'audio' && audioUrl && (
            <audio controls className="effect-audio">
              <source src={audioUrl} type="audio/mpeg" />
            </audio>
          )}
        </div>
      </div>
    </UploadFormItem>
  );
});

import { PATH } from 'app/constants/path';
import { deleteAllCookies } from 'app/hooks';
import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import history from 'utils/history';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config: any) {
    if (Cookies.get('access_token_admin')) {
      config.headers = {
        Authorization: `Bearer ${Cookies.get('access_token_admin')}`,
      };
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    switch (error.response.status) {
      case 404:
        // history.push(PATH.ERROR_404);
        break;
      case 500:
        // history.push(PATH.ERROR_500);
        break;
      case 503:
        // history.push(PATH.ERROR_503);
        break;
      case 401:
        deleteToken(error);
        break;
      case 403:
        deleteToken(error);
        break;
    }
    return Promise.reject(error);
  },
);

//refresh token
const deleteToken = async (error: any) => {
  deleteAllCookies();
  Cookies.remove('access_token_admin');
  history.push(PATH.LOGIN);
};

export default instance;

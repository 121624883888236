import { loginRequest, userRequest } from 'app/apis/auth';
import { call, put, takeLatest } from 'redux-saga/effects';
import { authActions as actions } from '.';
import { push } from 'connected-react-router';
import { PATH } from 'app/constants/path';
import { actionsToast } from 'app/pages/ToastPage/slice';
import { toast } from 'react-toastify';

function* login(action: any) {
  try {
    const res = yield call(loginRequest, action.payload);
    yield put(actions.loginSuccess(res));
    yield put(actions.getUser({}));
    toast.success('Sign in successfully');
    yield put(push(PATH.HOME));
  } catch (error: any) {
    yield put(actions.loginFailed(error));
    yield put(
      actionsToast.openToast({
        message: error.response.data.message,
        type: 'error',
      }),
    );
  }
}

function* getUser(action: any) {
  try {
    const res = yield call(userRequest);
    yield put(actions.getUserSuccess(res));
  } catch (error: any) {
    yield put(actions.getUserFailed(error));
    if (error.response.status !== 403) {
      yield put(
        actionsToast.openToast({
          message: error.response.data.message,
          type: 'error',
        }),
      );
    }
  }
}

export function* authSaga() {
  yield takeLatest(actions.login.type, login);
  yield takeLatest(actions.getUser.type, getUser);
}

import { useState } from 'react';
import Cookies from 'js-cookie';

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};

export default function useToken() {
  const getToken = () => {
    return Cookies.get('access_token_admin');
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: string) => {
    Cookies.set('access_token_admin', userToken);
    setToken(userToken);
  };

  const remove = () => {
    deleteAllCookies();
    Cookies.remove('access_token_admin', {
      path: '',
    });

    setToken('');
  };

  return {
    setToken: saveToken,
    token,
    remove,
  };
}

/**
 *
 * MetaInput
 *
 */
import { Form, FormInstance, Image, Input } from 'antd';
import React, { memo } from 'react';
import styled from 'styled-components/macro';

import { EyeIcon, EyeHidenIcon } from 'styles/image';

interface Props {
  name: string;
  rules?: any;
  placeholder?: string;
  type?: string;
  label?: string | React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  boldlabel?: boolean;
  dependencies?: string[];
  inform?: boolean;
  typeInput?: 'subtitle' | 'title';
  min?: number;
  max?: number;
  maxLength?: number;
  onInput?: any;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  style?: any;
  suffix?: any;
  value?: any;
  defaultValue?: string;
  minLength?: number;
  onPressKey?: any;
  onBlur?: any;
  onMouseLeave?: any;
  onKeyDown?: any;
  form: FormInstance<any>;
  focusOut?: boolean;
  isNormalize?: boolean;
  hideArrow?: boolean;
  bordered?: boolean;
}

export const MetaInput = memo((props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const { t, i18n } = useTranslation();
  const {
    name,
    rules,
    placeholder,
    type,
    label,
    dependencies,
    className,
    disabled = false,
    inform,
    required,
    typeInput = 'title',
    min = 0,
    max,
    onInput,
    style,
    maxLength,
    onChange,
    suffix,
    value,
    defaultValue,
    minLength = 0,
    onPressKey,
    onBlur,
    onMouseLeave,
    onKeyDown,
    form,
    focusOut = true,
    isNormalize = false,
    hideArrow,
    bordered = true,
  } = props;

  const disableCopy = e => {
    e.preventDefault();
  };

  const normalize = value => {
    return value.trim();
  };

  const handleBlur = e => {
    const value = e.target.value;
    if (form) form.setFieldValue(name, value?.trim());
    if (onBlur) onBlur(e);
  };

  const validateInput = (_, value) => {
    if (!rules) return Promise.resolve();
    if (value && value !== '' && rules) {
      const trimmedValue = value.trim();

      if (!trimmedValue) {
        // return Promise.reject(new Error("入力必須です。"));
      }
      return Promise.resolve();
    }
    if (!value && rules.some(item => item.required)) {
      return Promise.reject();
    }
    if (value === '' && !rules.some(item => item.required)) {
      return Promise.resolve();
    }
  };

  if (type === 'password') {
    return (
      <FormItem
        name={name}
        rules={rules}
        label={label || null}
        dependencies={dependencies}
        required={required}
        normalize={normalize}
        validateTrigger="onBlur"
      >
        <InputPassword
          bordered={bordered}
          placeholder={placeholder}
          className={className}
          disabled={disabled}
          onChange={onChange}
          onCopy={disableCopy}
          // onPaste={disableCopy}
          onBlur={handleBlur}
          onMouseLeave={handleBlur}
          iconRender={visible =>
            visible ? (
              <span style={{ cursor: 'pointer' }}>
                <Image preview={false} alt="eye-icon" src={EyeIcon} />
              </span>
            ) : (
              <span style={{ cursor: 'pointer' }}>
                <Image preview={false} alt="eye-icon" src={EyeHidenIcon} />
              </span>
            )
          }
        />
      </FormItem>
    );
  } else {
    return (
      <FormItem
        name={name}
        rules={
          rules
            ? [{ validator: validateInput }, ...rules]
            : [{ validator: validateInput }]
        }
        label={label || null}
        dependencies={dependencies}
        inform={inform ? inform.toString() : undefined}
        required={required}
        typeInput={typeInput}
        validateTrigger={
          focusOut ? ['onBlur', 'onFocus', 'onSubmit'] : undefined
        }
        scrollToFirstError={true}
        normalize={isNormalize ? normalize : undefined}
      >
        <InputCustom
          hideArrow={hideArrow}
          type={type}
          placeholder={placeholder}
          className={className}
          disabled={disabled}
          bordered={bordered}
          min={min}
          max={max}
          name={name}
          maxLength={maxLength}
          minLength={minLength}
          onInput={onInput}
          onChange={onChange}
          style={style}
          suffix={suffix}
          value={value}
          defaultValue={defaultValue}
          onPressEnter={onPressKey}
          onBlur={handleBlur}
          onMouseLeave={onMouseLeave}
          onKeyDown={onKeyDown}
        />
      </FormItem>
    );
  }
});

const InputCustom = styled(Input)<any>`
  padding: 12px 20px 12px 22px;
  border-radius: 10px;
  border-color: #d1d5db;
  height: 44px;

  &:hover {
    border-color: #ff347d;
  }

  &:focus {
    border-color: #ff347d;
    box-shadow: 0px 0px 14px 0px #ff00001a;
  }

  &::placeholder {
    color: #5f5858;
    font-weight: 400;
    font-size: 14px;
  }

  &.inlines {
    border: 1px solid #ff347d;
    border-radius: 8px;
    background: #fff;
  }

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: ${props => props.hideArrow && 'none'};
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }
`;

export const FormItem = styled(Form.Item)<any>`
  width: 100%;
  margin-bottom: 10px;

  .ant-input-affix-wrapper-disabled {
    background: var(--color-gray-4) !important;
  }

  .ant-form-item-label {
    align-items: flex-start !important;
    padding-bottom: 4px;
    label {
      font-size: 14px;
      color: #928c8c;
      font-weight: 400;
    }
  }
  label {
    font-size: 14px;
    color: #928c8c;
    font-weight: 400;
    &::after {
      content: ${props => (props.label === ' ' ? '" "' : '":"')} !important;
    }
  }

  .ant-form-item-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 4px;
    label {
      font-size: 14px;
      color: #928c8c;
      font-weight: 400;
    }
  }
  .ant-form-item-explain-error {
    color: #e10000 !important;
  }
  .ant-form-item-explain-error:first-child {
    display: block;
  }

  .ant-input-status-error,
  .ant-input-affix-wrapper-status-error {
    border-color: #e10000 !important;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    background-color: #494443 !important;
    border-color: #5f5858 !important;
    color: #fff;

    &:hover {
      border-color: #ff347d !important;
    }

    &:focus {
      border-color: #ff347d;
      box-shadow: 0px 0px 14px 0px #ff00001a;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: #fff;
    caret-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px #494443 inset;
    transition: background-color 5000s ease-in-out 0s;
    outline: none;
  }
`;

const InputPassword = styled(Input.Password)`
  padding: 12px 20px 12px 22px;
  border-radius: 10px;
  border-color: #d1d5db;
  height: 44px;

  input {
    &::placeholder {
      color: #5f5858;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &.inlines {
    border: 1px solid #ff347d;
    border-radius: 8px;
    background: #fff;
  }
`;

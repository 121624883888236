/**
 *
 * MetaButton
 *
 */
import { Button } from 'antd';
import React, { memo } from 'react';
import styled from 'styled-components/macro';

interface Props {
  text: string;
  type?:
    | 'primary'
    | 'default'
    | 'dashed'
    | 'text'
    | 'link'
    | 'ghost'
    | 'danger'
    | 'normal'
    | 'small'
    | 'back'
    | 'header';
  htmlType?: any;
  icon?: any;
  color?: string;
  width?: string;
  boldlabel?: boolean;
  onClick?: (event: MouseEvent) => void;
  style?: React.CSSProperties;
  marginright?: boolean;
  size?: 'large' | 'middle' | 'small';
  is_loading?: boolean;
  disabled?: boolean;
  className?: string;
}

export const MetaButton = memo((props: Props) => {
  const {
    text,
    type,
    htmlType,
    icon,
    color,
    width,
    marginright,
    size,
    is_loading = false,
  } = props;

  return (
    <ButtonCustom
      type={type}
      size={size || 'middle'}
      htmlType={htmlType}
      icon={icon}
      color={color}
      width={width}
      marginright={marginright}
      loading={is_loading}
      {...props}
    >
      {text}
    </ButtonCustom>
  );
});

const ButtonCustom = styled(Button)<any>`
  border-radius: 7px;
  font-size: 16px;
  font-weight: 700;
  padding: 4px 32px;
  margin-right: ${props => (props.marginright ? '20px' : '0px')};
  color: #fff;

  &:hover {
    background-color: ${props => props.theme.color_hover} !important;
    border: ${props => `1px solid transparent`} !important;
    color: #fff !important;
  }

  &:active {
    background-color: ${props => props.theme.color_hover} !important;
    border: ${props => `1px solid transparent`} !important;
    color: #fff !important;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  &.ant-btn-primary {
    height: 32px;
    background-color: ${props => props.theme.color_primary};
    color: #fff !important;
    &:hover {
      background-color: ${props => props.theme.color_hover} !important;
    }
  }

  &.ant-btn-primary.btn-primary-blue {
    background-color: #166ff6 !important;
    &:hover {
      background-color: #166ff6 !important;
      opacity: 0.8;
    }
  }

  &.ant-btn-primary.btn-primary-pink {
    background-color: #ff347d !important;
    transition: background-color 0.5s;
    &:hover {
      background-color: #a21749 !important;
    }
    &:disabled {
      cursor: not-allowed;
      background: #d2cfce;
      color: #928c8c !important;
    }
  }

  &.ant-btn-primary.btn-primary-lightgrey {
    background-color: #80808099 !important;
    &:hover {
      background-color: #80808099 !important;
      opacity: 0.8;
    }
  }

  &.ant-btn-primary.btn-primary-white {
    background-color: #fff !important;
    border: 1px solid #8080807d;
    &:hover {
      background-color: #ececec !important;
      border: 1px solid #8080807d;
      span {
        color: #808080;
      }
      .dark-icon {
        filter: invert(87%) sepia(99%) saturate(3%) hue-rotate(262deg)
          brightness(111%) contrast(97%);
      }
    }
    span {
      color: #808080;
    }
  }

  &.ant-btn-primary.btn-primary-dark {
    background-color: #000 !important;
    &:hover {
      background-color: #000 !important;
      opacity: 0.8;
    }
  }

  &.ant-btn-primary.btn-primary-green {
    background-color: #2aa12a !important;
    &:hover {
      background-color: #2aa12a !important;
      opacity: 0.8;
    }
  }

  &.ant-btn-primary.btn-primary-darkblue {
    background-color: #0d4497 !important;
    &:hover {
      background-color: #0d4497 !important;
      opacity: 0.8;
    }
  }

  &.ant-btn-default {
    background: #d2cfce;
    color: #928c8c !important;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 111.111% */

    &:hover {
      background: #d2cfcede !important;
      color: #928c8c !important;
    }
  }

  &.ant-btn-black {
    height: 32px;
    background: ${props => props.theme.color_hover};
    opacity: 0.3;
    color: #fff;
  }

  &.ant-btn-header {
    height: 32px;
    background: ${props => props.theme.color_secondary};
    color: #fff;
    border: ${props => `1px solid ${props.theme.color_secondary}`};
    min-width: 180px;
  }

  &.ant-btn-small {
    background: #fff;
    color: ${props => props.theme[props.color]};
    border: 1px solid ${props => props.theme[props.color]};
    height: 28px !important;
    border-radius: 7px !important;
    padding: 0px 20px;
  }

  &.ant-btn-normal {
    background: #d2cfce;
    color: #928c8c !important;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 111.111% */

    &:hover {
      background: #d2cfcede !important;
      color: #928c8c !important;
    }
  }

  &.ant-btn-back {
    height: 32px;
    color: ${props => props.theme.color_primary};
    background: #fff;
    border: ${props => `1px solid ${props.theme.color_primary}`};
    width: ${props => props.width};
    padding: 0px 20px;
    font-weight: bold;
  }

  &.ant-btn-ghost {
    height: 32px;
    background-color: ${props => props.theme.color_warning};
    color: #fff;
    border-color: ${props => props.theme.color_warning};
  }
  &:disabled {
    cursor: not-allowed;
    background: #d2cfce;
    color: #928c8c !important;
    opacity: 0.8;
    :hover {
      background: #d2cfce !important;
      color: #928c8c !important;
    }
  }
`;

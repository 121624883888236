import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { authSaga } from './saga';
import Cookies from 'js-cookie';
import { AuthState, LoginRequestType } from './types';

export const initialState: AuthState = {
  currentUser: null,
  isLoading: false,
  isLoadingUser: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Login
    login(state, action: PayloadAction<LoginRequestType>) {
      state.isLoading = true;
    },
    loginSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      Cookies.set('access_token_admin', action.payload?.data?.accessToken);
    },
    loginFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    getUser(state, action: PayloadAction<any>) {
      state.isLoadingUser = true;
    },
    getUserSuccess(state, action: PayloadAction<any>) {
      state.isLoadingUser = false;
      state.currentUser = action.payload.data;
    },
    getUserFailed(state, action: PayloadAction<any>) {
      state.isLoadingUser = false;
    },
  },
});

export const { actions: authActions } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authSaga });
  return { actions: slice.actions };
};

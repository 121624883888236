import { Carousel, Form, Image, Typography } from 'antd';
import { MetaTitle } from 'app/components';
import { MetaHelmet } from 'app/components/MetaHelmet';
import { PATH } from 'app/constants/path';
import useToken from 'app/hooks/useToken';
import LoginForm from 'app/pages/AuthPage/components/LoginForm';
import { useAuthSlice } from 'app/pages/AuthPage/slice';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginBanner1, LoginBanner2, LoginBanner3 } from 'styles/image';
import { selectAuth } from '../slice/selectors';
import { Box, CarouseBox, TabBox, Wrapper } from './styles';

export const SignInUpPage = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const location = useLocation();
  const { token } = useToken();

  const { actions } = useAuthSlice();
  const { isLoading } = useSelector(selectAuth);

  const { pathname } = location;

  const [key, setKey] = useState('login');

  const onFinish = useCallback(
    (values: any) => {
      dispatch(actions.login(values));
    },
    [actions, dispatch, pathname],
  );

  useEffect(() => {
    form.setFieldsValue({ username: '', password: '' });
  }, [form]);

  useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, [navigate, token]);

  useEffect(() => {
    if (pathname === PATH.LOGIN) {
      setKey('login');
    } else {
      setKey('register');
    }
  }, [pathname]);

  return (
    <Wrapper>
      <MetaHelmet title={pathname === PATH.LOGIN ? 'Sign In' : 'Sign Up'} />
      <Box className="form-content">
        <MetaTitle
          text="Welcome Back!"
          subText="Welcome back, please enter your detail!"
        />
        <TabBox>
          <Typography.Title level={2}>Sign in</Typography.Title>
        </TabBox>

        <LoginForm form={form} onFinish={onFinish} />
      </Box>
      <CarouseBox>
        <Carousel autoplay effect="fade" speed={2000} infinite>
          <Image src={LoginBanner1} alt="login_banner_1" preview={false} />
          <Image src={LoginBanner2} alt="login_banner_2" preview={false} />
          <Image src={LoginBanner3} alt="login_banner_3" preview={false} />
        </Carousel>
      </CarouseBox>
    </Wrapper>
  );
});

// css
